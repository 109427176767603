<script setup>

    const error = useError();
    const router = useRoute();
    const handleError = () => {
        clearError({
            redirect: '/',
        });
    };
    
    const viewStack = ref( false )

    if( useRequestURL().host.includes('localhost') ){
        viewStack.value = true
    }

</script>

<template>
    <div v-if="error?.statusCode == 500" class="container">
        <h1>{{ error?.statusCode }}</h1>
        
    </div>
    <NuxtLayout name="error" v-if="error">
        <div class="w-full h-full flex items-center justify-center pb-40" v-if="error.statusCode == 404">
            <div class="me-6">
                <p class="text-brand-primary font-extrabold text-[9rem]">{{ error.statusCode }}</p>
            </div>
            <div>
                <p class="font-extrabold text-[3rem] text-brand-dark">Страницы не существует</p>
                <a @click="handleError" class="text-white bg-brand-primary px-3.5 py-1.5 rounded-md text-base cursor-pointer my-3 inline-block">На главную</a>
            </div>
        </div>
        <div class="w-full h-full flex items-center justify-center pb-40" v-if="error.statusCode == 500">
            <div class="me-6">
                <p v-if="viewStack"  class="text-brand-primary font-extrabold text-[9rem]">{{ error.statusCode }}</p>
                <p v-if="!viewStack"  class="text-brand-primary font-extrabold text-[9rem]">УПС</p>
            </div>
            <div>
                <p v-if="!viewStack" class="font-extrabold text-[3rem] text-brand-dark">Сайт на обслуживании</p>
                <div v-if="viewStack" v-html="error?.stack" ></div>
            </div>
        </div>
    </NuxtLayout>
</template>