import validate from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  redirectbc: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/middleware/redirectbc.js"),
  redirectbci: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/middleware/redirectbci.js"),
  redirectspace: () => import("/home/gitlab-runner/builds/pG9KGa4F5/0/sim-sol/website/middleware/redirectspace.js")
}